var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c('div',{staticClass:"title"},[_vm._v("新建菜单")]),_c('div',{staticClass:"form"},[_c('a-form',{attrs:{"form":_vm.form,"label-col":{ span: 6 },"wrapper-col":{ span: 18 },"colon":false},on:{"submit":_vm.handleSubmit}},[_c('a-row',[_c('a-col',{attrs:{"lg":12,"md":12,"sm":24}},[_c('a-form-item',{attrs:{"label":"名称"}},[_c('a-input',{directives:[{name:"decorator",rawName:"v-decorator",value:([
                'name',
                {
                  rules: [{ required: true, message: '请输入！' }],
                },
              ]),expression:"[\n                'name',\n                {\n                  rules: [{ required: true, message: '请输入！' }],\n                },\n              ]"}]})],1)],1),_c('a-col',{attrs:{"lg":12,"md":12,"sm":24}},[_c('a-form-item',{attrs:{"label":"路径"}},[_c('a-input',{directives:[{name:"decorator",rawName:"v-decorator",value:([
                'path',
                {
                  rules: [{ required: true, message: '请输入！' }],
                },
              ]),expression:"[\n                'path',\n                {\n                  rules: [{ required: true, message: '请输入！' }],\n                },\n              ]"}]})],1)],1),_c('a-col',{attrs:{"span":12}},[_c('a-form-item',{attrs:{"label":"上级菜单"}},[_c('a-cascader',{directives:[{name:"decorator",rawName:"v-decorator",value:(['pid']),expression:"['pid']"}],attrs:{"placeholder":"","changeOnSelect":"","options":_vm.menuList,"fieldNames":{
                label: 'name',
                value: 'id',
                children: 'children',
              }}})],1)],1),_c('a-col',{attrs:{"lg":12,"md":12,"sm":24}},[_c('a-form-item',{attrs:{"label":"权限"}},[_c('a-select',{directives:[{name:"decorator",rawName:"v-decorator",value:([
                'permission',
                {
                  rules: [{ required: true, message: '请选择！' }],
                },
              ]),expression:"[\n                'permission',\n                {\n                  rules: [{ required: true, message: '请选择！' }],\n                },\n              ]"}],attrs:{"show-search":""}},_vm._l((_vm.permissionList),function(item){return _c('a-select-option',{key:item.code,attrs:{"value":item.code}},[_vm._v(_vm._s(item.name))])}),1)],1)],1),_c('a-col',{attrs:{"lg":12,"md":12,"sm":24}},[_c('a-form-item',{attrs:{"label":"类型"}},[_c('a-radio-group',{directives:[{name:"decorator",rawName:"v-decorator",value:([
                'type',
                {
                  rules: [{ required: true, message: '请选择！' }],
                },
              ]),expression:"[\n                'type',\n                {\n                  rules: [{ required: true, message: '请选择！' }],\n                },\n              ]"}]},_vm._l((_vm.menuTypeList),function(item){return _c('a-radio',{key:item.value,attrs:{"value":item.value}},[_vm._v(" "+_vm._s(item.name)+" ")])}),1)],1)],1),_c('a-col',{attrs:{"lg":12,"md":12,"sm":24}},[_c('a-form-item',{attrs:{"label":"状态"}},[_c('a-radio-group',{directives:[{name:"decorator",rawName:"v-decorator",value:([
                'status',
                {
                  rules: [{ required: true, message: '请选择！' }],
                },
              ]),expression:"[\n                'status',\n                {\n                  rules: [{ required: true, message: '请选择！' }],\n                },\n              ]"}]},_vm._l((_vm.statusList),function(item){return _c('a-radio',{key:item.value,attrs:{"value":item.value}},[_vm._v(" "+_vm._s(item.name)+" ")])}),1)],1)],1),_c('a-col',{attrs:{"lg":12,"md":12,"sm":24}},[_c('a-form-item',{attrs:{"label":"排序"}},[_c('a-input-number',{directives:[{name:"decorator",rawName:"v-decorator",value:([
                'sort',
                {
                  rules: [{ required: true, message: '请输入！' }],
                },
              ]),expression:"[\n                'sort',\n                {\n                  rules: [{ required: true, message: '请输入！' }],\n                },\n              ]"}],staticStyle:{"width":"100%"}})],1)],1),_c('a-col',{attrs:{"lg":12,"md":12,"sm":24}},[_c('a-form-item',{attrs:{"label":"平台"}},[_c('a-radio-group',{directives:[{name:"decorator",rawName:"v-decorator",value:([
                'platform',
                {
                  rules: [{ required: true, message: '请选择！' }],
                },
              ]),expression:"[\n                'platform',\n                {\n                  rules: [{ required: true, message: '请选择！' }],\n                },\n              ]"}]},_vm._l(([
                  { name: 'PC', value: 'pc' },
                  { name: 'App', value: 'app' },
                ]),function(item){return _c('a-radio',{key:item.value,attrs:{"value":item.value}},[_vm._v(" "+_vm._s(item.name)+" ")])}),1)],1)],1),_c('a-col',{attrs:{"lg":24,"md":24,"sm":24}},[_c('a-form-item',{attrs:{"label":"描述","label-col":{ span: 3 },"wrapper-col":{ span: 21 }}},[_c('a-textarea',{directives:[{name:"decorator",rawName:"v-decorator",value:(['remarks', {}]),expression:"['remarks', {}]"}],attrs:{"auto-size":{ minRows: 3 }}})],1)],1)],1),_c('div',{staticClass:"center"},[_c('a-space',[_c('a-button',{on:{"click":_vm.cancel}},[_vm._v("关闭")]),_c('a-button',{attrs:{"htmlType":"submit","type":"primary"}},[_vm._v("保存")])],1)],1)],1)],1)])
}
var staticRenderFns = []

export { render, staticRenderFns }